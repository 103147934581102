import React from "react";
import FAQ from "components/FAQ";
import Footer from "components/Footer";
import GeneralInfo from "components/GeneralInfo";
import Header from "components/Header";
import HeaderInfo from "components/HeaderInfo";
import Time from "components/Time";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Prizes from "components/Prizes";
import Organizers from "components/Organizers";
import constants from "site-constants";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import NewsCard from "components/NewsCard";

const siteUrl = constants.siteUrl;

export default function Home() {
  const { t } = useTranslation("common");

  return (
    <>
      <Helmet>
        <title>{t`pageTitle`}</title>
        <meta name="title" content={t`pageTitle`} />
        <meta name="description" content={t`pageDescription`} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={t`pageTitle`} />
        <meta property="og:description" content={t`pageDescription`} />
        <meta property="og:image" content="/images/share.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={t`pageTitle`} />
        <meta property="twitter:description" content={t`pageDescription`} />
        <meta property="twitter:image" content="/images/share.png" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-1Y8LCEBD3R"
        ></script>
        <script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-1Y8LCEBD3R');
`}</script>
      </Helmet>

      <Header />
      <div className="relative">
        <div className="absolute top-0 w-full h-48 -mt-32 bg-gradient-to-b from-[#020d1b00] via-[#020d1b] to-[#111927]" />
      </div>
      <NewsCard />
      <HeaderInfo />
      <Time />
      <GeneralInfo />
      <Prizes />
      <FAQ />
      <Organizers />
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
