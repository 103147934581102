import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

export default function NewsCard() {
  const { t } = useTranslation();
  return (
    <section className="relative p-4 md:p-0">
      <article className="max-w-4xl mx-auto bg-white rounded-md ">
        <div className="p-4 text-2xl font-bold md:text-4xl">
          {t(`news-title`)}
        </div>
        <img src="/images/hakaton-vest-naslovna.jpg" alt={t(`news-title`)} />
        <div className="p-4 text-lg font-medium">
          {t(`news-excerpt`)}
          <Link
            to="/vesti/whois-hakaton"
            className="ml-2 font-semibold text-red-500"
          >
            {t(`news-readMore`)}
          </Link>
        </div>
      </article>
    </section>
  );
}
